<template>
  <section class="app-ecommerce-details">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="uiProduct === undefined"
    >
      <h4 class="alert-heading">
        Error fetching product data
      </h4>
      <div class="alert-body">
        No item found with this item slug. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-e-commerce-shop'}"
        >
          Shop
        </b-link>
        for other items.
      </div>
    </b-alert>

    <!-- Content -->
    <b-card
      v-if="uiProduct"
      no-body
    >
      <b-card-body>
        <b-row class="my-2">

          <!-- Left: Product Image Container -->
          <b-col
            cols="12"
            md="5"
            class="d-flex align-items-center justify-content-center mb-2 mb-md-0"
          >
            <div class="d-flex align-items-center justify-content-center">
              <b-img
                :src="uiProduct.images[0]"
                :alt="`Image of ${uiProduct.name}`"
                class="product-img"
                fluid
                style="max-height:250px"
              />
            </div>
          </b-col>

          <!-- Right: Product Details -->
          <b-col
            cols="12"
            md="7"
          >

            <!-- Product Name -->
            <h4>{{ uiProduct.name }}</h4>
            <!-- Product Brand -->
            <b-card-text class="item-company mb-0">
              <span>Category : </span>
              <b-link class="company-name">
                {{ uiProduct.category }} <br>
              </b-link>
             
            </b-card-text>
             <br> <b-card-text class="item-company mb-0">
              <span>SubCategory : </span>
              <b-link class="company-name">
                {{ uiProduct.subCategory }} <br>
              </b-link>
             
            </b-card-text> <br>

            <b-card-text class="item-company mb-0">
              <span>Discount : </span>
              <b-link class="company-name">
                {{ uiProduct.discount }} % <br>
              </b-link>
            </b-card-text> <br>

              <b-card-text class="item-company mb-0">
              <span>Transaction type : </span>
              <b-link class="company-name">
                {{ uiProduct.typeTransaction }} <br>
              </b-link>
            </b-card-text>

            <!-- Price And Ratings -->
            <div class="ecommerce-details-price d-flex flex-wrap mt-1">
              <h4 class="item-price mr-1">
                ${{ uiProduct.price }}
              </h4>
              <ul class="unstyled-list list-inline pl-1 border-left">
                <li
                  v-for="star in 5"
                  :key="star"
                  class="ratings-list-item mr-25"
                >
                  <feather-icon
                    icon="StarIcon"
                    size="18"
                    :class="[{'fill-current': star <= uiProduct.rating}, star <= uiProduct.rating ? 'text-warning' : 'text-muted']"
                  />
                </li>
              </ul>
            </div>

            <!-- Avability -->
            <!--<b-card-text>Available - <span class="text-success">In stock</span></b-card-text>-->

            <!-- Product Description -->
            <b-card-text>{{ uiProduct.description }}</b-card-text>

            <!-- Product Meta [Free shpping, EMI, etc.] -->
           <!-- <ul class="product-features list-unstyled">
              <li v-if="product.hasFreeShipping">
                <feather-icon icon="ShoppingCartIcon" />
                <span>Free Shipping</span></li>
              <li>
                <feather-icon icon="DollarSignIcon" />
                <span>EMI options available</span>
              </li>
            </ul>-->

            <hr>

            <!-- Colors -->
            <!--<div class="product-color-options">
              <h6>Colors</h6>
              <ul class="list-unstyled mb-0">
                <li
                  v-for="color in uiProduct.option"
                  :key="color._id"
                  class="d-inline-block"
                  :class="{'selected': selectedColor === color.value}"
                  @click="selectedColor = color.value"
                >
                  <div
                    class="color-option"
                    :class="`b-${color.value}`"
                  >
                    <div
                      class="filloption"
                      :class="`bg-${color.value}`"
                    />
                  </div>
                </li>
              </ul>
            </div>-->
            <h6 v-if="uiProduct.option.length>0">Options : </h6>
            <div v-for="item in uiProduct.option" :key="item._id">
              <b-card-text class="item-company mb-0">
              <span>{{item.subject}} : </span>
              <b-link class="company-name">
                {{ item.value }} <br>
              </b-link>
             
            </b-card-text>
            </div>

            <hr v-if="uiProduct.option.length>0">

            <div class="d-flex flex-column flex-sm-row pt-1">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-cart mr-0 mr-sm-1 mb-1 mb-sm-0"
                @click="_edit(uiProduct)"
              >
                <feather-icon
                   icon="EditIcon"
                  class="mr-50"
                />
                <span>Edit</span>
              </b-button>
              <b-button
                variant="danger"
                class="btn-wishlist mr-0 mr-sm-1 mb-1 mb-sm-0"
                @click="_delete(uiProduct)"
              >
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                  :class="{'text-danger': uiProduct.isInWishlist}"
                />
                <span>Delete</span>
              </b-button>
           
            </div>
          </b-col>
        </b-row>
      </b-card-body>

      <!-- Static Content -->
     <!-- <e-commerce-product-details-item-features />-->

      <!-- Static Content -->
      <!-- Slider: Related Products -->
    <!--  <e-commerce-product-details-related-products /> -->
    </b-card>
  </section>
</template>

<script>
import { useRouter } from '@core/utils/utils'
import store from '@/store'
import { ref } from '@vue/composition-api'
import {
  BCard, BCardBody, BRow, BCol, BImg, BCardText, BLink, BButton, BDropdown, BDropdownItem, BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ECommerceProductDetailsItemFeatures from './ECommerceProductDetailsItemFeatures.vue'
import ECommerceProductDetailsRelatedProducts from './ECommerceProductDetailsRelatedProducts.vue'
import { useEcommerceUi } from '../useEcommerce'
import PostsMixin from "../post.mixin.js";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  mixins: [PostsMixin],
  props:["product"],
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,

    // SFC
    ECommerceProductDetailsItemFeatures,
    ECommerceProductDetailsRelatedProducts,
  },
 
  data () {
    return {
      uiProduct : {
        images : [""],
        postId :''
      }
    }
  },
  mounted() {
    store.dispatch('app-ecommerce/fetchProducts')
    if(this.product!=undefined) {
          this.uiProduct = this.product
          console.log("test tt ",this.uiProduct)
    }
  },
  methods : {
    _edit(item) {
       console.log("test item edit ",item)
         this.$router.push({
              name: "apps-e-commerce-product-edit",
              params: { product: item, id: item.postId },
            });
    },
     _delete(item) {
        this.$swal({
        title: "Êtes-vous sûr?",
        text: "Vous ne pouvez pas annuler votre action",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes Delete it!",
        cancelButtonText: "No, Keep it!",
        confirmButtonColor: 'red',
        cancelButtonColor: "green",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
            store.dispatch('app-ecommerce/removeProduct', item).then(data => {
              if(data!=="error") {
                this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Product successfully deleted',
                  icon: "TrashIcon",
                  variant: 'success',
                },
              })
              
                this.$router.push({
                 name: "apps-e-commerce-shop"
                 });
     
              }else{
                this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Failed delete',
                  icon: "TrashIcon",
                  variant: 'danger',
                },
              })
              }
            })
        
        } else {
           this.$toast({
               component: ToastificationContent,
                props: {
                  title: 'Annuler la suppression ',
                  icon: "TrashIcon",
                  variant: 'danger',
                },
            })
        }
      });
      }
  },
  watch: {
   posts: function() {
      for(let i=0; i<this.posts.length; i++) {
        if(this.posts[i]._id===this.$route.params.id) {
          this.uiProduct = this.posts[i].product
          this.uiProduct.postId = this.posts[i]._id

            if (this.posts[i].ratings.length > 0)
              this.uiProduct.rating = this.posts[i].ratings[0].mark
            else
              this.uiProducts.rating = 0
            break
        }
      }
        
   } 
  },
  setup() {
    const { handleCartActionClick, toggleProductInWishlist } = useEcommerceUi()

 
    // UI
    const selectedColor = ref(null)


    return {
      selectedColor,
      handleCartActionClick,
      toggleProductInWishlist,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";
</style>
